import { Component, Input, OnInit, Optional } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { BankInformations } from "../../../../@core/data/bases/base-model";
import { NgForm } from "@angular/forms";
import { Bank, BankData } from "../../../../@core/data/bank";
import { ToastService } from "../../../../@core/utils/toast/toast.service";
import { BaseResponsePagination } from "src/app/@core/data/bases/base-response";
import { AccountType, AccountTypeData } from "src/app/@core/data/account-type";
import { BaseResponse } from "src/app/@core/data/bases/base-response";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";

@Component({
  selector: "ngx-email-select",
  templateUrl: "./bank-informations-select.component.html",
  styleUrls: ["./bank-informations-select.component.scss"],
})
export class BankInformationsSelectComponent implements OnInit {
  submitted: boolean = false;
  banks: Bank[] = [];
  bankInformations: BankInformations = {
    account: "",
    accountTypeId: "",
    agency: "",
    bankId: 0,
    bankName: "",
  };
  pagination: any = { currentPage: 1, perPage: 1000 };
  bankFilter: Bank = {};
  accountTypes: AccountType[] = [];
  @Input() contains: BankInformations[] = [];

  constructor(
    private bankData: BankData,
    private accountTypeService: AccountTypeData,
    private toast: ToastService,
    @Optional() protected ref: NbDialogRef<BankInformationsSelectComponent>
  ) {}

  ngOnInit(): void {
    this.bankData.list(this.bankFilter, this.pagination).subscribe(
      (result) => {
        this.banks = result.data;
      },
      (error) => {
        this.toast.errorMsg("Erro obtendo a lista de bancos");
      }
    );
    this.loadAccountTypes();
  }
  public loadAccountTypes() {
    this.accountTypeService
      .list({ pageSize: environment.pages.defaultForOptions.recordsPerPage })
      .pipe(
        catchError((error) => {
          this.toast.errorMsg("Ocorreu um erro ao buscar tipos de conta.");
          return error;
        })
      )
      .subscribe(
        (response: BaseResponse<AccountType[], BaseResponsePagination>) => {
          this.accountTypes = response.result;
        }
      );
  }
  confirmBankInformation(form: NgForm) {
    if (
      this.contains.find(
        (bd) =>
          bd.bankId == this.bankInformations.bankId &&
          bd.agency == this.bankInformations.agency &&
          bd.account == this.bankInformations.account &&
          bd.accountTypeId == this.bankInformations.accountTypeId
      )
    ) {
      // form.controls.address.setErrors({ invalid: true })
      return;
    }

    if (this.bankInformations.bankId) {
      var bank = this.banks.find((b) => b.id == this.bankInformations.bankId);
      var accountType = this.accountTypes.find(
        (at) => at.id == this.bankInformations.accountTypeId
      );
      if (bank) this.bankInformations.bankName = bank.name;
      if (accountType) this.bankInformations.accountTypeName = accountType.name;
      else {
        form.controls.bankId.setErrors({ required: true });
        return;
      }

      this.ref.close(this.bankInformations);
    }
  }

  close() {
    this.ref.close();
  }
}
